import { useCallback, useEffect, useState } from "react";
import { List, message, Upload, Collapse } from "antd";
import api from "../../../../services/api";

import {
  IPesagemFerroviariaV2,
  IVagao,
} from "../../interfaces/IPesagemFerroviariaV2";
import { Container, Wrapper } from "./style";
import { AiFillDownCircle, AiFillRightCircle } from "react-icons/ai";
import { Header } from "../../../../components/v1/header";
import { Section } from "../../../../components/v1/section";

const BalancaFerroviariaComponent = () => {
  const { Panel } = Collapse;
  const [loading] = useState(false);
  const [pesagemFerroviaria, setPesagemFerroviaria] =
    useState<IPesagemFerroviariaV2>();
  const [ultimaPesagem, setUltimaPesagem] = useState<IVagao>();
  const [countOccurrence, setCountOccurrence] = useState(0);

  // recuperar os dados das pesagens
  const initialData = useCallback(() => {
    api
      .get<IPesagemFerroviariaV2>("/balancaferroviaria")
      .then(({ data }) => {
        const { vagoes } = data;

        // ordenar composicao DESC ( -1 )
        const dataOrder = vagoes?.sort((a, b) =>
          a.ordemVagao < b.ordemVagao ? 1 : -1
        );
        setPesagemFerroviaria({
          ...data,
          vagoes: dataOrder,
        });
        setUltimaPesagem(dataOrder?.[0] || {});

        const countOccurrence = dataOrder
          ?.slice(0, dataOrder.length)
          ?.filter((o) => o?.vagoesComOcorrencia.length > 0 || o.alerta);
        setCountOccurrence(countOccurrence?.length!);
      })
      .catch(() => {
        message.error("Não foi possivel buscar as pesagens");
      });
  }, []);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (!loading) {
      interval = setInterval(() => {
        initialData();
      }, 1200);
    } else {
      if (interval) {
        clearInterval(interval);
      }
    }
    return () => clearInterval(interval as unknown as NodeJS.Timeout);
  }, [initialData, loading]);

  return (
    <Wrapper>
      <Container>
        <Header title="Validação de Pesagem">
          <Upload
            name="file"
            accept={".xls,.xlsx"}
            action={`${process.env.REACT_APP_MAXIMUS_APP_BACKEND}/boletimACM/uploadPlanilhaAcm`}
            onChange={({ file }) => {
              if (file.status === "done") {
                message.success("Upload realizado com sucesso");
              }
            }}
            maxCount={1}
            method="POST"
            headers={{
              Authorization: `Bearer ${window.localStorage.getItem(
                "@App:token"
              )}`,
            }}
          >
            <button className="upload-btn">Upload Planilha ACM</button>
          </Upload>
        </Header>

        {/* <!-- Composição --> */}
        <Section title="Composicao">
          <div className="composicao-info">
            <p>{pesagemFerroviaria?.nomeComposicao || ""}</p>
          </div>
        </Section>

        {/* <!-- Vagão Atual --> */}
        <Section title="Vagão Atual">
          <div className="vagao-info">
            <div className="info-box">
              <p>
                <strong>Posição:</strong> {ultimaPesagem?.ordemVagao || 0}
              </p>
              <p>
                <strong>Vagão:</strong> {ultimaPesagem?.tipo || ""}
              </p>
            </div>
            <div className="info-box">
              <p>
                <strong>Peso:</strong>{" "}
                {`${Intl.NumberFormat("pt-BR").format(
                  ultimaPesagem?.pesoFinal || 0
                )} TNS`}
              </p>
              <p>
                <strong>Peso Max. Permitido:</strong>
                {`${Number(ultimaPesagem?.pesoMaximoPermitido || 0).toFixed(
                  0
                )} TNS`}
              </p>
            </div>
          </div>
        </Section>

        <Section title="Status da Pesagem">
          <div className="status-box">
            {countOccurrence >= 0 ? (
              <p>
                ✔ {countOccurrence} ocorrência{countOccurrence < 1 ? "" : "s"}
              </p>
            ) : (
              <p>
                ❌ {countOccurrence} ocorrência{countOccurrence < 1 ? "" : "s"}
              </p>
            )}
          </div>
        </Section>

        {/* <!-- Lista de Vagões --> */}
        <Section>
          <Collapse
            size="small"
            bordered={false}
            expandIcon={({ isActive }) =>
              isActive ? (
                <AiFillDownCircle size={24} />
              ) : (
                <AiFillRightCircle size={24} />
              )
            }
            expandIconPosition="end"
            accordion
          >
            <Panel header={<h2>Lista de Vagões</h2>} key={1}>
              <div className="vagao-list">
                <List
                  itemLayout="vertical"
                  size="small"
                  dataSource={pesagemFerroviaria?.vagoes || []}
                  renderItem={(item: IVagao, index) => (
                    <div
                      className="vagao-item"
                      {...(item?.alerta && item.status === "max_exceeded"
                        ? { style: { backgroundColor: "#e8afaf" } }
                        : {})}
                    >
                      <ul>
                          <li>Posição: {item?.ordemVagao || ""}</li>
                        {/* <li>
                          Velocidade:
                          {`${
                            Number(item?.velocidadeMedia).toFixed(0) || 0
                          } km/h`} */}
                        {/* </li> */}
                        {/* <li>Categoria: {item?.tipo || ""}</li> */}
                        {/* <li>
                          Peso Max Permitido:
                          {`${Intl.NumberFormat("pt-BR").format(
                            item.pesoMaximoPermitido || 0
                          )} TNS`}
                        </li> */}
                        <li>Número do Vagão: {item?.identificacaoVagao || ""}</li>
                        <li>
                          Peso Vagão:
                          {`${Intl.NumberFormat("pt-BR").format(
                            item?.pesoFinal || 0
                          )} TNS`}
                        </li>
                      </ul>
                    </div>
                  )}
                />
              </div>
            </Panel>
          </Collapse>
        </Section>
      </Container>
    </Wrapper>
  );
};
export default BalancaFerroviariaComponent;
